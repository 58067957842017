import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import HeaderLargeDesktop from '../HeaderLargeDesktop';
import HeroLargeDesktop2 from '../HeroLargeDesktop2';
import Button03 from '../Button03';
import PrimaryButton2 from '../PrimaryButton2';
import Story1 from '../Story1';
import ApplyNow from '../ApplyNow';
import Footer from '../Footer';
import './LrgCareers.scss';
import splitBanner from '../../static/img/ewirabracket-1x-png-1x-png@1x.png';
import splitBanner2 from '../../static/img/PageBannerScan_crop.png';
import Line2 from '../../static/img/line-2-1@1x.png';

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -200;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

class LrgCareers extends React.Component {
  render() {
    const {
      place,
      whoWeAreLink,
      anglesRight2,
      careersPlaceholder,
      spanText1,
      spanText2,
      spanText3,
      spanText4,
      spanText5,
      spanText6,
      spanText7,
      spanText8,
      spanText9,
      spanText10,
      spanText11,
      spanText12,
      spanText13,
      spanText14,
      spanText15,
      spanText16,
      spanText17,
      spanText18,
      spanText19,
      spanText20,
      spanText21,
      spanText22,
      spanText23,
      spanText24,
      spanText25,
      spanText26,
      headerLargeDesktopProps,
      heroLargeDesktop2Props,
      button031Props,
      primaryButton21Props,
      primaryButton22Props,
      storyProps,
      applyNowRole1Props,
      applyNowRole2Props,
      applyNowRole3Props,
      applyNowRole4Props,
      applyNowRole5Props,
      applyNowRole6Props,
      footerProps,
    } = this.props;

    return (
      <div className='container-center-horizontal'>
        <div className='lrg-careers screen'>
          <Helmet>
            <meta
              name='robots'
              content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
            />
            <title>Careers | Rivelin</title>
            <meta name='description' content='Our people is what makes us special' />
            <meta
              name='keywords'
              content='intelligent industrial robotics, industrial robotics machine learning, metal additive manufacturing automation, manufacturing artificial intelligence, intelligent supply chains, robotics engineering, NetShape, Rivelin'
            />
            <meta property='og:description' content='Our people is what makes us special' />
            <meta property='og:site_name' content='Rivelin Robotics' />
            <meta property='og:title' content='Careers' />
            <meta name='og:image' content='https://www.rivelinrobotics.com/img/image-2-1x-png@1x.png' />
            <meta
              name='og:image:secure_url'
              content='https://www.rivelinrobotics.com/img/image-2-1x-png@1x.png'
            />
            <meta
              name='image'
              property='og:image'
              content='https://www.rivelinrobotics.com/img/image-2-1x-png@1x.png'
            />
            <meta property='og:image:type' content='image/png' />
          </Helmet>
          <HeaderLargeDesktop {...headerLargeDesktopProps} />
          <HeroLargeDesktop2
            heroLargeDesktop2={heroLargeDesktop2Props?.heroLargeDesktop2.heroLargeDesktop2}
            title={heroLargeDesktop2Props.title}
          />
          <div className='nav-bar-12'>
            <HashLink to='/#top'>
              <div className='place-1 atkinsonhyperlegible-normal-onyx-22px'>{place}</div>
            </HashLink>
            <img className='angles-right' src={anglesRight2} alt={anglesRight2} />
            <HashLink to='/who-we-are#top'>
              <div className='who-we-are-link atkinsonhyperlegible-normal-onyx-22px'>{whoWeAreLink}</div>
            </HashLink>
            <img className='angles-right' src={anglesRight2} alt={anglesRight2} />
            <div className='careers-placeholder atkinsonhyperlegible-normal-onyx-22px'>
              {careersPlaceholder}
            </div>
          </div>
          <div className='heading-1'>
            <div className='our-people-is-what-m'>
              <span className='span0-1 atkinsonhyperlegible-bold-bunker-40px'>{spanText1}</span>
              <span className='span1-1'>{spanText2}</span>
              <span className='atkinsonhyperlegible-normal-onyx-22px'>{spanText3}</span>
              <a
                href='mailto:info@rivelinrobotics.com'
                className='span3 atkinsonhyperlegible-normal-onyx-22px'
              >
                {spanText4}
              </a>
              <span className='atkinsonhyperlegible-normal-onyx-22px'>{spanText5}</span>
            </div>
            <div className='overlap-group-container'>
              <HashLink smooth to='/who-we-are#team-rb' scroll={(el) => scrollWithOffset(el)}>
                <div className='button-container-1'>
                  <Button03 />
                  <Button03 className={button031Props.className} />
                </div>
              </HashLink>
              <HashLink smooth to='/careers#open-positions' scroll={(el) => scrollWithOffset(el)}>
                <div className='primary-button-container-12'>
                  <PrimaryButton2
                    primaryButton={primaryButton21Props.primaryButton}
                    openPositions={primaryButton21Props.openPositions}
                    className={primaryButton21Props.className}
                  />
                  <PrimaryButton2
                    primaryButton={primaryButton22Props.primaryButton}
                    openPositions={primaryButton22Props.openPositions}
                    className={primaryButton22Props.className}
                  />
                </div>
              </HashLink>
            </div>
          </div>
          <Story1 {...storyProps} />
          <div className='open-positions' id='open-positions'>
            <div className='split-banner' style={{ backgroundImage: `url(${splitBanner})` }}>
              <div className='overlap-group-28' style={{ backgroundImage: `url(${splitBanner2})` }}>
                <div className='join-our-teamsolve atkinsonhyperlegible-normal-white-22px-2'>
                  <span className='atkinsonhyperlegible-normal-white-22px'>{spanText6}</span>
                  <span className='span1-2 atkinsonhyperlegible-normal-white-34px'>{spanText7}</span>
                  <span className='atkinsonhyperlegible-normal-white-22px'>{spanText8}</span>
                </div>
              </div>
            </div>
            <div className='flex-row-4'>
              <div className='autonomous-intellige atkinsonhyperlegible-normal-onyx-22px-2'>
                <span className='span0-2 atkinsonhyperlegible-normal-onyx-22px'>{spanText9}</span>
                <span className='span1-3 atkinsonhyperlegible-bold-onyx-32px'>{spanText10}</span>
                <span className='atkinsonhyperlegible-normal-onyx-24px'>{spanText11}</span>
              </div>
              <a
                href={applyNowRole1Props.link}
                target='_blank'
                rel='noopener noreferrer'
                title='AdminOps Wizard pdf'
              >
                <ApplyNow applyNow={applyNowRole1Props.applyNow} />
              </a>
            </div>
            <img className='line-2' src={Line2} alt={Line2} />
            <div className='flex-row-4'>
              <div className='autonomous-intellige atkinsonhyperlegible-normal-onyx-22px-2'>
                <span className='span0-2 atkinsonhyperlegible-normal-onyx-22px'>{spanText12}</span>
                <span className='span1-3 atkinsonhyperlegible-bold-onyx-32px'>{spanText13}</span>
                <span className='atkinsonhyperlegible-normal-onyx-24px'>{spanText14}</span>
              </div>
              <a
                href={applyNowRole2Props.link}
                target='_blank'
                rel='noopener noreferrer'
                title='Mechanical Engineer pdf'
              >
                <ApplyNow applyNow={applyNowRole2Props.applyNow} />
              </a>
            </div>
            <img className='line-2' src={Line2} alt={Line2} />
            <div className='flex-row-4'>
              <div className='autonomous-intellige atkinsonhyperlegible-normal-onyx-22px-2'>
                <span className='span0-2 atkinsonhyperlegible-normal-onyx-22px'>{spanText15}</span>
                <span className='span1-3 atkinsonhyperlegible-bold-onyx-32px'>{spanText16}</span>
                <span className='atkinsonhyperlegible-normal-onyx-24px'>{spanText17}</span>
              </div>
              <a
                href={applyNowRole3Props.link}
                target='_blank'
                rel='noopener noreferrer'
                title='Industrial Designer pdf'
              >
                <ApplyNow applyNow={applyNowRole3Props.applyNow} />
              </a>
            </div>
            <img className='line-2' src={Line2} alt={Line2} />
            <div className='flex-row-4'>
              <div className='autonomous-intellige atkinsonhyperlegible-normal-onyx-22px-2'>
                <span className='span0-2 atkinsonhyperlegible-normal-onyx-22px'>{spanText18}</span>
                <span className='span1-3 atkinsonhyperlegible-bold-onyx-32px'>{spanText19}</span>
                <span className='atkinsonhyperlegible-normal-onyx-24px'>{spanText20}</span>
              </div>
              <a
                href={applyNowRole4Props.link}
                target='_blank'
                rel='noopener noreferrer'
                title='Automation Engineer pdf'
              >
                <ApplyNow applyNow={applyNowRole4Props.applyNow} />
              </a>
            </div>
            <img className='line-2' src={Line2} alt={Line2} />
            <div className='flex-row-4'>
              <div className='autonomous-intellige atkinsonhyperlegible-normal-onyx-22px-2'>
                <span className='span0-2 atkinsonhyperlegible-normal-onyx-22px'>{spanText21}</span>
                <span className='span1-3 atkinsonhyperlegible-bold-onyx-32px'>{spanText22}</span>
                <span className='atkinsonhyperlegible-normal-onyx-24px'>{spanText23}</span>
              </div>
              <a
                href={applyNowRole5Props.link}
                target='_blank'
                rel='noopener noreferrer'
                title='Applications Engineer pdf'
              >
                <ApplyNow applyNow={applyNowRole5Props.applyNow} />
              </a>
            </div>
            <img className='line-2' src={Line2} alt={Line2} />
            <div className='flex-row-4'>
              <div className='autonomous-intellige atkinsonhyperlegible-normal-onyx-22px-2'>
                <span className='span0-2 atkinsonhyperlegible-normal-onyx-22px'>{spanText24}</span>
                <span className='span1-3 atkinsonhyperlegible-bold-onyx-32px'>{spanText25}</span>
                <span className='atkinsonhyperlegible-normal-onyx-24px'>{spanText26}</span>
              </div>
              <a
                href={applyNowRole6Props.link}
                target='_blank'
                rel='noopener noreferrer'
                title='C++ Software Engineer pdf'
              >
                <ApplyNow applyNow={applyNowRole6Props.applyNow} />
              </a>
            </div>
          </div>
          <Footer
            className={footerProps.className}
            privacyPolicyLinkProps={footerProps.privacyPolicyLinkProps}
            youtubeProps={footerProps.youtubeProps}
            linkedinProps={footerProps.linkedinProps}
            githubProps={footerProps.githubProps}
          />
        </div>
      </div>
    );
  }
}

export default LrgCareers;
